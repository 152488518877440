import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import FormGroup from '@mui/material/FormGroup';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from 'react-bootstrap/Spinner';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider } from '@mui/material';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';
import { API } from 'aws-amplify';
import "./App.css";
import LoaderButton from './LoaderButton';

export default function App() {
    const [consentFormText, setConsentFormText] = useState();
    const [faqText, setFaqText] = useState();
    const [viewSignatureBtn, setViewSigBtn] = useState(false);
    const [modalType, setModalType] = useState("canvas");
    const [imageURL, setImageURL] = useState(null);
    const [show, setShow] = useState(false);
    const [sendingData, isSendingData] = useState(false);
    const [email, setEmail] = useState("N/A");
    const [phone, setPhone] = useState("N/A");
    const [name, setName] = useState("");
    const [organisation, setOrganisation] = useState("")
    const [postcode, setPostcode] = useState("")
    const [hideEmail, setHideEmail] = useState(true);
    const [hidePhone, setHidePhone] = useState(true);
    const [enquiryReason, setEnquiryReason] = useState("");
    const [pageLoading, setPageLoading] = useState(true);
    const sigCanvas = useRef();
    const handleClear = () => sigCanvas.current.clear();
    const handleClose = () => setShow(false);

    const handleShow = () => {
        setModalType("canvas");
        setShow(true);
    }


    const theme = useTheme({
        breakpoints: {
            values: {
                xxs: 0, // small phone
                xs: 300, // phone
                sm: 600, // tablets
                md: 900, // small laptop
                lg: 1200, // desktop
                xl: 1536 // large screens
            }
        }
    });

    const [tickboxLabels, setLabels] = useState([]);
    const [consentOptions, setConsentOptions] = useState();

    useEffect(() => {
        
        const onLoad = async ()  => {
            const requestOptions = {
                method: 'GET',
                headers: {"Content-Type":"application/json"},
            }


            API.get("http-api", "/getWebData", requestOptions).then(async (response) => {
                setConsentOptions(response.contactOptions);
                setConsentFormText(response.consentFormText);
                setFaqText(response.faqText);
                setLabels(response.tickboxes);
                setPageLoading(false);
            })

            
        }

        onLoad();
    }, [])

    const createSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            setImageURL(URL);
            setViewSigBtn(true);
            handleClose();
        }
        else {
            window.alert("Canvas cannot be empty");
        }
    }

    const viewSignature = () => {
        setModalType("image");
        setShow(true);
    }

    const consentBoxesHandler = (event) => {
        let updatedValue = {}


        switch (event.target.id) {
            case "inform-user":
                updatedValue = { "inform-user": event.target.checked }
                setConsentOptions(consentOptions => ({ ...consentOptions, ...updatedValue }));
                break;
            case "email":
                setHideEmail(!hideEmail);
                updatedValue = { "email": event.target.checked }
                setConsentOptions(consentOptions => ({ ...consentOptions, ...updatedValue }));
                break;
            case "phone":
                //If text is also selected, don't change the state, otherwise change it
                consentOptions.text ? setHidePhone(hidePhone) : setHidePhone(!hidePhone);
                updatedValue = { "phone": event.target.checked }
                setConsentOptions(consentOptions => ({ ...consentOptions, ...updatedValue }));
                break;
            default:
                break;
        }

    }

    const submitHandler = async (event) => {
        event.preventDefault();
        console.log("submitted")
        isSendingData(true);
        
        const data = {
            "name": name,
            "organisation": organisation,
            "postcode": postcode,
            "email": email || "N/A",
            "phone": phone || "N/A",
            "signature": imageURL,
            "consentOptions": consentOptions,
            "enquiryReason": enquiryReason
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        };

        API.post("http-api", "/send",
            requestOptions).then(() => {
                window.alert("Data submitted");
                window.location.reload();
            }).catch((error) => {
                alert(error)
            });

    }

    return (
        <>
            {!pageLoading ? (
                <div>
                    <div className="img-container">
                        <img className="img" src="https://www.yhrocu.org.uk/media/1368/large-colour.png" alt=""></img>
                    </div>

                    <h1>NEBRC Consent Sharing Form</h1>

                    <div className="top-box p-3">
                        <p>{consentFormText}</p>

                        <div className="container m-2 p-2">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox size="medium"
                                    id={"inform-user"}
                                    onChange={consentBoxesHandler} />}
                                    label={tickboxLabels[0]} />
                            </FormGroup>
                        </div>

                        <p>{faqText}</p>
                    </div>

                    <div className="form">
                        <div className="left-col">
                            <div className='form'>
                                <div className='left-col'>
                                    <p className='ps-1'>I consent to contact by:</p>
                                    <FormGroup id="consent-form" className='p-3'>
                                        <FormControlLabel control={<Checkbox size="medium" id="email"
                                            onChange={consentBoxesHandler} />} label="Email" />
                                        <FormControlLabel control={<Checkbox size="medium" id="phone"
                                            onChange={consentBoxesHandler} />} label="Phone" />
                                        <TextField required className="w-100 bg-light" size='small' onChange={e => setEnquiryReason(e.target.value)}
                                            id="enquiryReason" placeholder='Enter reason or N/A if not applicable' label="Reason for enquiry" multiline={true} set inputProps={{ maxLength: 200 }} variant="filled" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="right-col">
                            <ThemeProvider theme={theme}>
                                <Box
                                    sx={{
                                        '& .MuiTextField-root': {
                                            m: 1, width: {
                                                xs: "90%",
                                                sm: "40%",
                                                md: "40%",
                                                lg: "40%",
                                            },
                                        },
                                    }}
                                >
                                <div>
                                <Form onSubmit={submitHandler}>
                                <Form.Group className="m-2">
                                    <TextField size='small' onChange={e => setName(e.target.value)}
                                        required id="name" set inputProps={{ maxLength: 100 }}  label="Name" variant="standard" />

                                    <TextField size='small' onChange={e => setOrganisation(e.target.value)}
                                        id="organisation" label="Organisation" required set inputProps={{ maxLength: 20 }} variant="standard" />
                                    
                                    <TextField size='small' onChange={e => setPostcode(e.target.value)}
                                        id="postcode" label="Postcode" required set inputProps={{ maxLength: 20 }} variant="standard" />

                                    <TextField size='small' hidden={hideEmail} onChange={e => setEmail(e.target.value)}
                                        id="email" label="Email" required={!hideEmail} set inputProps={{ maxLength: 150 }} variant="standard" />

                                    <TextField size='small' hidden={hidePhone} onChange={e => setPhone(e.target.value)}
                                        id="phone" label="Phone" required={!hidePhone} set inputProps={{ maxLength: 20 }} variant="standard" />
                                </Form.Group>

                                <Button variant="primary" className="m-2 p-3" onClick={handleShow}>
                                    Sign Signature
                                </Button>

                                {viewSignatureBtn && <Button variant="primary" className="m-2 p-3"
                                    onClick={viewSignature}>
                                    View E-Signature
                                </Button>}

                                <Modal show={show} onHide={handleClose} size="lg" centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>E-Signature</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {(modalType === "canvas" &&
                                            <SignatureCanvas penColor='black'
                                                canvasProps={{ className: 'sigCanvas' }}
                                                backgroundColor="whitesmoke"
                                                ref={sigCanvas}
                                                clearOnResize={false}
                                            />
                                        )}

                                        {(modalType === "image" &&
                                            <img src={imageURL} alt="signature" className="sigCanvas" />
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>

                                        {(modalType === "canvas" &&
                                        <>
                                        <Button variant="secondary" onClick={handleClear}>
                                            Clear
                                        </Button>
                                        <Button variant="primary" onClick={createSignature}>
                                            Save Changes
                                        </Button>
                                        </>
                                        )}
                                    </Modal.Footer>
                                </Modal>

                                <LoaderButton isLoading={sendingData} variant="success" type="submit" className="m-2 p-3">
                                    Submit Form
                                </LoaderButton>
                                </Form>
                                </div>
                                </Box>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            ): (
            <div className='loading'>
                <Spinner animation="border" role="status">
                </Spinner>
            </div>
            )}
        </>
    )
}